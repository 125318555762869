import React from "react"
import { Link } from "gatsby"

import GDPR from "./gdpr"
import { rhythm } from "../utils/typography"

const Layout = ({ location, title, children }) => {


  const [showDrawer, setShowDrawer] = React.useState(false)
  const [showGDPR, setShowGDPR] = React.useState(false)

  React.useEffect(() => {
    if (localStorage.getItem("RGPD_raidboss.app") === null)
      setShowGDPR(true)
  }, [])

  const [loaded, isLoaded] = React.useState(false)

  React.useEffect(() => {
    if (localStorage.getItem("RGPD_raidboss.app") === null) return
    if (typeof window !== "undefined" && window.document) {
      let ezstandalone = window.ezstandalone || {}
      if (!ezstandalone || !ezstandalone.cmd) {
        const ezoicTag = document.createElement("script")
        ezoicTag.src = "https://www.ezojs.com/ezoic/sa.min.js"
        ezoicTag.addEventListener("load", () => isLoaded(true))
        document.body.appendChild(ezoicTag)
      }
    }
  }, [showGDPR])

  React.useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.document &&
      window.location.host === "www.raidboss.app"
    ) {
      let ezstandalone = window.ezstandalone || {}

      try {
        ezstandalone.cmd = ezstandalone.cmd || []
        ezstandalone.cmd.push(function () {
          const GDPRAgreed =
            localStorage.getItem("RGPD_raidboss.app") === "true"
          ezstandalone.setDisablePersonalizedStatistics(!GDPRAgreed)
          ezstandalone.setDisablePersonalizedAds(!GDPRAgreed)
          if (location.pathname !== "/")
            ezstandalone.define(
              101,
              103,
              104,
              105,
              106,
              107,
              108,
              109,
              110,
              111
            )
          ezstandalone.enable()
          ezstandalone.display()
        })

        return () => {
          if (ezstandalone.refresh) ezstandalone.refresh()
        }
      } catch (e) {
        console.log({ e })
      }
    }
  }, [loaded, location.pathname])

  const rootPath = `${__PATH_PREFIX__}/`

  const header = (
    <h3
      style={{
        fontFamily: `Montserrat, sans-serif`,
        marginTop: 0,
      }}
    >
      <Link
        style={{
          boxShadow: `none`,
          color: `inherit`,
        }}
        to={`/`}
      >
        {"Raid Boss"}
      </Link>
    </h3>
  )

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: location.pathname === rootPath ? "100%" : rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        fontFamily: `Montserrat, sans-serif`,
      }}
    >
      <div
        style={{
          maxWidth: location.pathname === rootPath ? 1000 : "100%",
          margin: "0 auto",
        }}
      >
        <header>{header}</header>
      </div>
      <main>{children}</main>
        <div id="ezoic-pub-ad-placeholder-101"> </div>
      <footer>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            margin: "20px auto",
            maxWidth: location.pathname === rootPath ? 1000 : "100%",
          }}
        >
          <div style={{ display: "block" }}>
            <div>
              <Link to="/">Raid Boss</Link>
            </div>
            <div>
              <Link to="/current-raid-bosses/">Current raid bosses</Link>
            </div>
            <div>
              <Link to="/pokemon-go-type-chart/">Pokemon GO Type Chart</Link>
            </div>
            <div>
              <Link to="/pokedex-pokemon-go/">Pokemon GO Pokedex</Link>
            </div>
            <div>
              <Link to="/about/">About</Link>
            </div>
          </div>
          <div style={{ display: "block" }}>
            <div>
              <a href="https://bit.ly/raidbossapp">Get the App on Android</a>
            </div>
            <div>
              <a href="https://twitter.com/raidbossapp">Raid Boss on Twitter</a>
            </div>
            <div>
              <a href="https://instagram.com/raidbossapp">
                Raidboss on Instagram
              </a>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          © {new Date().getFullYear()}, Raid Boss made with ❤️ by <a href="https://www.thomascollart.com" target="_blank">Thomas Collart</a>
        </div>
      </footer>
      {showGDPR ? <GDPR setShowGDPR={setShowGDPR} /> : null}
    </div>
  )
}

export default Layout
