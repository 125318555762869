import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    a: {
      boxShadow: `none`,
      color: "#fa5a5a",
    },
    h4: {
      margin: 0,
      fontFamily: "inherit",
      lineHeight: "inherit",
      fontWeight: "bold",
    },
    "td, th": {
      padding: 0,
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      borderBottom: "1px solid hsla(0,0%,0%,0.12)",
      borderRight: "1px solid hsla(0,0%,0%,0.12)",
      fontSize: "10px",
      textAlign: "center",
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
