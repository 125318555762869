import React from "react"
import styled from "styled-components"

const Modal = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  background: #00000042;
  padding-top: 24px;
  z-index: 42000;
  left: 0;
  top: 0;
`

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  margin: 24px;
  padding: 4px;
  margin: auto;
  background-color: white;
  border: 8px solid #007cc9;
`

const Title = styled.div`
  font-weight: bold;
  color: black;
  @media only screen and (min-width: 768px) {
    margin-bottom: 8px;
  }
`

const Paragraph = styled.p`
  color: black;
  font-size: 0.7em;
`

const ButtonsGroup = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
  }
  text-align: center;
  margin-bottom: 8px;
`

const Button = styled.div`
  padding: 6px 8px;
  font-size: 0.75em;
  @media only screen and (min-width: 768px) {
    padding: 12px 18px;
  }
`

const Agree = styled(Button)`
  background-color: #007cc9;
  color: white;
  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`

const Disagree = styled(Button)`
  background-color: white;
  color: #007cc9;
  border: 1px solid #007cc9;
`

const SecondaryLink = styled.a`
  font-size: 0.6em;
  margin: 4px;
  color: #007cc9 !important;
  box-shadow: none;
`

const GDPR = ({ setShowGDPR }) => {
  const handleClick = agreed => {
    localStorage.setItem("RGPD_raidboss.app", agreed.toString())
    setShowGDPR(false)
  }

  return (
    <Modal>
      <Wrapper>
        <Title>Confidentialité et Transparence</Title>
        <Paragraph>
          Nous et nos partenaires utilisons des cookies pour Stocker et/ou
          accéder à des informations sur un terminal. Ces données sont utilisées
          pour Publicités et contenu personnalisés, mesure de performance des
          publicités et du contenu, données d’audience et développement de
          produit. Un exemple de données traitées peut être un identifiant
          unique stocké dans un cookie. Certains de nos partenaires peuvent
          traiter vos données dans le cadre de leurs intérêts commerciaux
          légitimes sans vous demander votre consentement. Pour connaître les
          raisons pour lesquelles ils estiment avoir un intérêt légitime ou pour
          s'opposer à ce traitement de données, utilisez le lien de la liste des
          fournisseurs ci-dessous. Le consentement soumis ne sera utilisé que
          pour le traitement des données provenant de ce site web. Si vous
          souhaitez modifier vos paramètres ou retirer votre consentement à tout
          moment, le lien pour ce faire se trouve dans notre politique de
          confidentialité accessible depuis notre page d'accueil.
        </Paragraph>
        <ButtonsGroup>
          <Agree onClick={() => handleClick(true)}>
            Autoriser tous les cookies et continuer
          </Agree>
          <Disagree onClick={() => handleClick(false)}>
            Refuser tous les cookies et continuer
          </Disagree>
        </ButtonsGroup>
        <SecondaryLink
          href="https://g.ezoic.net/privacy/raidboss.app#vendorList"
          target="_blank"
          rel="noopener noreferrer"
        >
          Liste de Fournisseurs
        </SecondaryLink>
        <span style={{ color: "black", fontSize: "0.75em" }}>|</span>
        <SecondaryLink href="/mentions-legales/" target="_blank">
          Politique de confidentialité
        </SecondaryLink>
      </Wrapper>
    </Modal>
  )
}

export default GDPR
